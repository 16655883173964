<template>
  <div class="main">

          <div class="title">
      <div class="titleBox" @click="GoLunBoUrl(smallLB[0].Description)">
        <img src="../../assets/img/zmb_location.png" alt="" />
        <span>区域地理位置</span>
      </div>
      <div class="titleBox" @click="goZMlist('/ZMBorientation')">
        <img src="../../assets/img/zmb_orientation.png" alt="" />
        <span>功能定位</span>
      </div>
      <div class="titleBox" @click="goZMlist('/ZMBcase')">
        <img src="../../assets/img/zmb_innovate.png" alt="" />
        <span>创新案例名录</span>
      </div>
      <div class="titleBox" @click="goZMlist('/ZMBpolicy')">
        <img src="../../assets/img/zmb_policy.png" alt="" />
        <span>相关政策</span>
      </div>
      <!-- <div class="titleBox">
        <img src="../../assets/img/zmb_services.png" alt="" />
        <span>企业服务</span>
      </div> -->
      <div class="titleBox" @click="goZMlist('/ZMBPolicyInformation')">
        <img src="../../assets/img/zmb_news.png" alt="" />
        <span>政策资讯</span>
      </div>
    </div>
    <!-- header end-->
    <div class="textbox" style="padding: 30px 0 0 20px">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/freeTrade' }"
          >自贸专栏</el-breadcrumb-item
        >
        <el-breadcrumb-item>企业服务</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- body start -->
    <div class="bodybox">
      <div class="textbox">
        <div class="lefttab">
          <div class="tab_title"><i class="el-icon-menu"></i> 服务分类</div>
          <div class="tab_list">
            <span
              :class="-1 == tabindex ? 'active' : ''"
              @click="GetActive(null, -1)"
              >全部</span
            >
            <span
              :class="tabindex == index ? 'active' : ''"
              v-for="(item, index) in CateList"
              @click="GetActive(item, index)"
              :key="index"
              >{{ item.Name }}</span
            >
          </div>
        </div>
        <div class="rightlist">
          <div class="listbox" v-for="(item, i) in ServeList" :key="i">
            <div class="box_title"><span></span> {{ item.CompanyName }}</div>
            <div class="box_cont">
              <el-tooltip
                class="item"
                effect="dark"
                :content="item.Title"
                placement="top-start"
              >
                <div class="cont_title">{{ item.Title }}</div>
              </el-tooltip>
              <div class="cont_infor" v-html="item.Intro"         ref="content"
          @click="imageEnlargement"></div>
              <div class="adrss">
                企业位置：<span>{{ item.CompanyAddress }}</span>
              </div>
              <div class="adrss">
                联系电话：<span>{{ item.Tel }}</span>
              </div>
              <div class="btn" @click="getComInfor(item.SysID)">查看详情</div>
            </div>
          </div>

          <div class="more" v-if="IsShowMore == 1">
            <span @click="GetPageMore()"
              >加载更多 <i class="el-icon-d-arrow-right"></i
            ></span>
          </div>
          <div class="more" v-if="IsShowMore != 1 && ServeList.length">
            <em>已加载全部</em>
          </div>
          <div class="more" v-if="IsShowMore != 1 && !ServeList.length">
            <em>暂无数据 </em>
          </div>
        </div>
      </div>
    </div>
    <!-- body end -->
  </div>
</template>

<script>
// 公共样式

import timeFormat from "../../util/time.js";
import "../../assets/css/common.css";
export default {
  data() {
    return {
      count: 5,
      options: [
        {
          value: "选项1",
          label: "技术创新",
        },
        {
          value: "选项2",
          label: "管理咨询",
        },
        {
          value: "选项3",
          label: "市场开拓",
        },
        {
          value: "选项4",
          label: "信息服务",
        },
        {
          value: "选项5",
          label: "人才培训",
        },
      ],
      value: "",
      tabindex: -1,
      activeIndex: "6",
      drawer: false,
      windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
      height: "",
      navlist: [
        {
          names: "首页",
          path: "/",
          id: "1",
        },
        {
          names: "政策资讯",
          path: "/news",
          id: "2",
        },
        {
          names: "产业载体",
          path: "/carrier",
          id: "3",
        },
        {
          names: "企业互动",
          path: "/interaction",
          id: "4",
        },
        {
          names: "金融服务",
          path: "/service",
          id: "5",
        },
        {
          names: "企业服务",
          path: "/enterpriseServices",
          id: "6",
        },
        {
          names: "招聘服务",
          path: "/recruit",
          id: "7",
        },
      ],
      arr: [
        "技术创新",
        "管理咨询",
        "市场开拓",
        "信息服务",
        "人才培训",
        "文创服务",
        "法律服务",
        "其他产品及服务",
      ],
      CateSysID: "",
      CateList: [],
      IsShowMore: 1,
      ServeList: [],
      PageSize: 10,
      PageIndex: 1,
      smallLB: [],
      imgArr:[],
    };
  },

  watch: {
    windowWidth(val) {
      let that = this;
      // console.log("实时屏幕宽度：", val);
      if (val > 990) {
        that.drawer = false;
        that.height = "400px";
      } else if (val <= 900 && val > 400) {
        that.height = "200px";
      } else {
        that.height = "100px";
      }
    },
  },
  methods: {
     imageEnlargement(e) {
   
      if (e.target.nodeName == "IMG") {
        //判断点击富文本内容为img图片
        console.log(e.target.currentSrc);
        this.imgArr[0] = e.target.currentSrc;
        console.log(this.imgArr);

         this.$viewerApi({
          images: this.imgArr
        })
     
      } else {
        console.log("点击内容不为img");
      }
    },

       //轮播跳转
    GoLunBoUrl(url) {
      if (url != null && url != "") {
        window.location.href=url;
      }
    },
    getLBsmail() {
      let that = this;
      that
        .$post("api/SysNews/List", {
          State: true,
          Type: "自贸区地理位置",
          IsHome: true,
          Sort:'Sort ASC'
        })
        .then(function (res) {
          console.log(res);
          let data = res.Obj;
          data.forEach((v) => {
            if (v.Img) {
              v.Img = that.urlOA + v.Img.substr(1);
            }
          });
          that.smallLB = data;
        });
    },
      goZMlist(url) {
      this.$router.push(url);
    },
    GetCateList() {
      let that = this;
      that
        .$post("/api/Dictionary/List", {
          State: true,
          Type: "ZMServeType",
          Sort: "Sort asc",
        })
        .then(function (data) {
          console.log(data);
          that.CateList = data.Obj;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //   服务详情
    getComInfor(id) {
      this.$router.push({ path: "/ZMBserviceDetails", query: { SysID: id } });
    },
    GetPageMore() {
      this.PageIndex = this.PageIndex + 1;
      this.GetServeList();
    },
    GetServeList() {
      let that = this;
      that
        .$post("/api/ZMServe/List", {
          State: true,

          Sort: "CreateOn desc",
          PageSize: that.PageSize,
          CateSysID: that.CateSysID,
          PageIndex: that.PageIndex,
        })
        .then(function (data) {
          console.log(data);
          let arr = data.Obj;
          arr.forEach((v) => {
            if (v.Logo) {
              v.Logo = that.urlOA + v.Logo.substr(1);
            }

            v.PublishTime = timeFormat.timeFormat(v.PublishTime, 2);
            // that.ServeList.push(v);
          });
          if (that.PageIndex == 1) {
            that.ServeList = arr;
          } else {
            that.ServeList = that.ServeList.concat(arr);
          }
          if (data.Obj.length == that.PageSize) {
            that.IsShowMore = 1;
          } else {
            that.IsShowMore = 0;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    GetActive(row, i) {
      this.tabindex = i;
      if (row != null) {
        this.CateSysID = row.SysID;
      } else {
        this.CateSysID = "";
      }

      this.PageIndex = 1;

      this.GetServeList();
    },
    handleSelect(e) {
      let that = this;
      console.log(e);
      that.$router.push(e);
      setTimeout(() => {
        that.drawer = false;
      }, 500);
    },
    getlogin() {
      this.$router.push("/login");
    },
    register() {
      this.$router.push("/register");
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },
  mounted() {
    var that = this;
 that.getLBsmail()
    this.GetCateList();
    this.GetActive(null, -1);
    let windowWidth = that.windowWidth;
    console.log(windowWidth);
    if (windowWidth < 900 && windowWidth > 400) {
      that.height = "200px";
    } else if (windowWidth < 400) {
      that.height = "150px";
    }
    // <!--把window.onresize事件挂在到mounted函数上-->
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight;
        window.fullWidth = document.documentElement.clientWidth;
        that.windowHeight = window.fullHeight; // 高
        that.windowWidth = window.fullWidth; // 宽
      })();
    };
  },
};
</script>


<style scoped  lang="scss">
.title {
  width: 100%;
  height: 80px;
  background-color: #eee;

  display: flex;
  justify-content: center;
  .titleBox {
    margin-top: 20px;
    height: 40px;
    margin-left: 40px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-right: 10px;
    img {
      height: 40px;
    }
    span {
      margin-left: 10px;
      font-weight: 550;
    }
  }
}
// 本页面
.lefttab {
  float: left;
  width: 260px;
  height: 100%;
  .tab_title {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    height: 40px;
    line-height: 40px;
    background-color: #ed982c;
    color: #fff;
  }
  .tab_list {
    width: 100%;

    box-sizing: border-box;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    line-height: 45px;
    color: #000;
    margin-bottom: 20px;
    span {
      display: block;
      width: 100%;
      padding: 0 40px;
      height: 45px;
      font-size: 14px;
      color: #878787;
      box-sizing: border-box;
      border-left: 1px solid #ccc;
      border-right: 1px solid #ccc;
      cursor: pointer;
    }
    span:hover {
      background-color: #f7f7f7;
    }
  }
}
.active {
  border-left: 3px solid #ed982c !important;
  background-color: #f7f7f7;
}

.textbox {
  width: 1230px;
  margin-left: 50%;
  transform: translateX(-50%);
  // background-color: #475669;
  // padding-top: 100px;
  overflow: hidden;
}

.rightlist {
  float: left;
  width: 950px;
  margin-left: 20px;

  // background-color: aqua;
  padding: 40px 0;
  box-sizing: border-box;
  .page {
    float: left;
    margin: 0;
    width: 90%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
  }
  .listbox {
    float: left;
    height: 290px;
    width: 45%;
    // background-color: yellowgreen;
    margin-left: 5%;
    margin-bottom: 30px;
    border: 1px solid #e2e2e2;
    box-sizing: border-box;
  }
  .box_title {
    position: relative;
    width: 100%;
    height: 60px;
    line-height: 20px;
    padding: 20px;
    box-sizing: border-box;
    font-size: 18px;
    background-color: #f7f7f7;
    border-bottom: 1px solid #e2e2e2;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    z-index: 999999;
    // overflow: hidden;
    span {
      position: absolute;
      bottom: -9px;
      left: 60px;
      height: 16px;
      width: 16px;
      border: 1px solid #e2e2e2;
      background-color: #fff;
      transform: rotateZ(45deg);
      z-index: 9999999999999999999;
    }
  }
  .box_cont {
    position: relative;
    height: 190px;
    width: 100%;
    background-color: #fff;
    z-index: 99999999;
    box-sizing: border-box;
    .btn {
      position: absolute;
      cursor: pointer;
      right: 20px;
      bottom: 0;
      height: 30px;
      padding: 0 16px;
      font-size: 12px;
      line-height: 30px;
      border-radius: 2px;
      border: 1px solid #ed982c;
      color: #ed982c;
    }
    .btn:hover {
      background-color: #ed982c;
      color: #fff;
    }
    .cont_title {
      font-weight: 600;
      height: 20px;
      padding: 0 20px;
      margin-top: 20px;
      margin-bottom: 20px;
      line-height: 20px;
      font-size: 18px;
      box-sizing: border-box;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .cont_infor {
      padding: 0 20px;
      box-sizing: border-box;
      font-size: 14px;
      height: 60px;
      line-height: 20px;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
      margin-bottom: 10px;
    }
    .adrss {
      height: 30px;
      line-height: 30px;
      font-size: 14px;
      padding: 0 20px;
      box-sizing: border-box;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;

      span {
        color: #ed982c;
      }
    }
  }
}

.aaa {
  display: none;
}
.selectbox {
  display: none;
  padding: 10px 40px;
  box-sizing: border-box;
}
@media (max-width: 1201px) {
  .textbox {
    width: 950px !important;
  }
  .lefttab {
    width: 200px;
  }
  .rightlist {
    width: 700px;
  }
}
@media (max-width: 921px) {
  .textbox {
    width: 700px !important;
  }
  .selectbox {
    display: block;
  }
  .lefttab {
    display: none;
  }
  .rightlist {
    width: 100%;
  }
  .rightlist .listbox {
    width: 40%;
  }

  .aaa {
    display: block;
  }

  .bannerbox img {
    height: 100%;
  }
  .main .textbox {
    width: 100%;
    overflow: hidden;
    padding-top: 10px;
  }
}
@media (max-width: 600px) {
  .selectbox {
    padding: 10px 0;
    box-sizing: border-box;
  }
  .textbox {
    width: 100% !important;
  }
  .lefttab {
    display: none;
  }
  .rightlist {
    width: 100%;
    margin-left: 15px;
    padding-top: 10px;
  }
  .rightlist .listbox {
    width: 90%;
    margin-left: 0;
  }

  .aaa {
    display: block;
  }

  .bannerbox img {
    height: 100%;
  }
  .main .textbox {
    width: 100%;
    overflow: hidden;
    padding-top: 10px;
  }
  .title {
    width: 100%;
    height: 60px;
    background-color: #eee;
    box-sizing: border-box;
    min-width: 300px;
    display: flex;
    justify-content: center;
    .titleBox {
      margin-top: 20px;
      height: 20px;
       margin-left: 10px;
    margin-right: 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      padding-right: 10px;
      img {
        display: none;
        height: 20px;
        width: 25px;
      }
      span {
        font-size: 12px;
        margin-left: 10px;
        font-weight: 550;
      }
    }
  }
}
</style>